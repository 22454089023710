.ant-form-item.ant-form-item-view-mode {
  margin-bottom: 20px;
}
.ant-form-item.ant-form-item-view-mode-v4 {
  margin-bottom: 5px;
}

.ant-form-item-view-mode .ant-form-item-label {
  text-align: left;
  line-height: 1.5;
}

.ant-form-item-view-mode .ant-form-item-control {
  line-height: 1.5;
}

.ant-form-item-children .antd-form-builder-string-content {
  line-height: 1.5;
  display: inline-block;
  padding-top: 9px;
}

.ant-form-item-view-mode .antd-form-builder-string-content {
  display: inline;
  padding-top: 0;
}

.antd-form-builder-question-icon {
  fill: #ed7203;
  width: 16px;
  height: 16px;
  vertical-align: middle;
}
