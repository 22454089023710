.filter-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.filter-button {
  border-radius: 20px;
}

.active-button.active {
  border: 1px solid #20CB8A; /* Cambia el color del borde según tu diseño */
  background-color: #e6fff0; /* Cambia el color de fondo según tu diseño */
}

.active-button {
  color: #20CB8A ;
}

.active-button:hover {
  color: #20CB8A !important;
  border-color: #20CB8A !important;
}

.active-button.active:hover{
  background-color: #e6fff0 !important;
}

.deleted-button.active {
  border: 1px solid #BF1824;
  background-color: #ffe6e6;
}

.deleted-button {
  color: #BF1824 ;
}

.deleted-button:hover {
  color: #BF1824 !important;
  border-color: #BF1824 !important;
}

.deleted-button.active:hover{
  background-color: #ffe6e6 !important;
}
