.ant-layout-header {
  height: 48px !important;
  line-height: normal !important;
  padding-left: 20px !important;
  padding-right: 10px !important;
}

.ant-menu {
  background: #141414 !important;
}

.ant-menu-inline-collapsed-tooltip {
  display: none !important;
}
