.ant-table-thead > tr > th {
  font-weight: 300 !important;
}

:where(.css-dev-only-do-not-override-gm4blf).ant-table-wrapper .ant-table-column-sorter-up.active,
:where(.css-dev-only-do-not-override-gm4blf).ant-table-wrapper .ant-table-column-sorter-down.active {
    color:#3057D1 !important; /* Cambiado a rojo */
}

.ant-btn-primary {
  background-color: #3057D1 !important;
}

.ant-btn-default:hover {
  border-color: #3057D1 !important;
  color: #3057D1 !important;
}

.ant-btn-dangerous {
  background-color: #BF1824 !important;
}

.ant-table-filter-trigger.active {
  color: #3057D1 !important;
}

.ant-pagination-item-active{
  border-color: #3057D1 !important;
}

.ant-pagination-item-active a {
  color: #3057D1 !important;
}

.ant-select-item-option-selected {
  background-color: #D0D8E3 !important;
}

.ant-spin-dot-item {
  background-color: #3057D1 !important;
}

.ant-table-column-sorter-down.active {
  color: #3057D1 !important;
}

.ant-table-column-sorter-up.active {
  color: #3057D1 !important;
}
