.ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  color:#726F6F
}

.ant-input-outlined {
  border-color: #D6E4FF !important;
}

.ant-input-outlined:focus, .ant-input-outlined:focus-within {
    border-color: #D0D8E3;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.1);
    outline: 0;
    background-color: #ffffff;
}

.ant-input-outlined:hover {
    border-color: #D0D8E3;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.1);
    outline: 0;
    background-color: #ffffff;
}

.ant-select-focused:where(.css-dev-only-do-not-override-gm4blf).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  border-color: #D0D8E3;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.1);
  outline: 0;
}

.ant-select-hover:where(.css-dev-only-do-not-override-gm4blf).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  border-color: #D0D8E3;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.1);
  outline: 0;
}

.ant-select-selector {
  border-color: #D6E4FF !important;
}

.ant-select-selector:hover {
  border-color: #D0D8E3 !important;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.1);
  outline: 0;
}
